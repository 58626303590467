.App {
  font-weight: 400;
  font-family: Lato;
  background-color: #121212;
  color: white;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}
/* WebKit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #121212; /* Dark background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #4a4a4a; /* Dark gray color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners of the scrollbar thumb */
  border: 3px solid #2c2c2c; /* Same color as track to create space effect */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666; /* Lighter gray on hover */
}

/* Firefox */
* {
  scrollbar-width: thin; /* Thinner scrollbar */
  scrollbar-color: #4a4a4a #121212; /* Thumb color, Track color */
}

/* Scrollbar Thumb Hover for Firefox */
*::-webkit-scrollbar-thumb:hover {
  scrollbar-color: #666 #121212;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

.cover {
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 600px;
  padding-top: 200px;
}

.coverName {
  font-size: 50px;
  text-align: center;
  animation: slideinLeft 3s;
  margin-bottom: 10px;
}

.coverDescription {
  font-size: 25px;
  text-align: center;
  animation: slideinLeft 3s;
}

.coverPhoto {
  animation: slideinRight 3s;
}

.about {
  font-size: 50px;
  text-align: center;
}

.aboutAll {
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 400px;
}

.aboutDescription {
  font-size: 18px;
  padding: 0px 5vw 5vw 5vw; /* Use viewport width for padding as well */
  min-width: 300px;
}

.skills {
  font-size: 50px;
  text-align: center;
}

.skillsAll {
  margin-bottom: 400px;
  overflow: hidden;
}

.skillButtons {
  text-align: center;
  background-color: #b8ca88 !important;
  border: 0 !important;
  color: #000 !important;
}

.skillButtons:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.skillNames {
  text-align: center;
  font-size: 20px;
  padding-top: 30px;
}

.skillIcons {
  font-size: 50px;
  transition: transform 0.75s;
  /* Animation */
  margin: 0 auto;
  margin-bottom: 10px;
  color: #b8ca88;
}

.skillIcons:hover {
  transform: scale(1.5);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.projects {
  font-size: 50px;
  text-align: center;
  margin-bottom: 30px;
}

.projectCards {
  justify-content: center;
  align-items: center;
  width: auto;
  margin-bottom: 10px;
}

.projectCardsBody {
  text-align: center;
}

.projectSlide {
  animation-duration: 2s;
  animation-name: slidein;
  overflow: hidden;
}

.contacts {
  text-align: center;
  font-size: 50px;
  justify-content: center;
  margin-bottom: 50px;
}

.contactButtons {
  background-color: #b8ca88 !important;
  border: 0 !important;
  color: #000 !important;
}

.contactButtons:hover {
  transform: scale(1.25);
}

.cardTech {
  text-align: center;
  font-size: 20px;
}

.flip {
  height: 60px;
  overflow: hidden;
}

.flip > div > div {
  color: #0d6efd;
  padding: 5px 15px;
  height: 55px;
  margin-bottom: 45px;
  display: inline-block;
}

.flip div:first-child {
  animation: show 5s linear infinite;
  animation-delay: 3.5s;
}

@keyframes slideinLeft {
  0% {
    transform: translate(-200px, 0px);
    opacity: 0;
  }

  50% {
    transform: translate(50px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes slideinRight {
  0% {
    transform: translate(200px, 0px);
    opacity: 0;
  }

  50% {
    transform: translate(-50px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(0px, 200px);
    opacity: 0;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes show {
  0% {
    margin-top: -270px;
  }

  5% {
    margin-top: -180px;
  }

  33% {
    margin-top: -180px;
  }

  38% {
    margin-top: -90px;
  }

  66% {
    margin-top: -90px;
  }

  71% {
    margin-top: 0px;
  }

  99.99% {
    margin-top: 0px;
  }

  100% {
    margin-top: -270px;
  }
}
